type TruncateOptions = {
  charsToRemain?: number;
  separator?: string;
  truncateFrom?: 'end' | 'middle' | 'start';
};

export const truncateVoyageNameStep = (voyageName: string, width?: number): string => {
  if (!width) return voyageName;
  if (width <= 900) return truncate(voyageName, { charsToRemain: 10 });
  if (width <= 1000) return truncate(voyageName, { charsToRemain: 20 });
  if (width <= 1200) return truncate(voyageName, { charsToRemain: 30 });
  return voyageName;
};

export const truncate = (
  fullStr: string,
  { charsToRemain = 8, separator = '...', truncateFrom = 'middle' }: TruncateOptions = {},
): string => {
  if (!(charsToRemain > 0) || fullStr.length <= charsToRemain + separator.length) {
    return fullStr;
  }

  switch (truncateFrom) {
    case 'start': {
      return separator + fullStr.slice(fullStr.length - charsToRemain);
    }
    case 'end': {
      return fullStr.slice(0, charsToRemain) + separator;
    }
    case 'middle':
    default: {
      const half = Math.floor(charsToRemain / 2);
      const start = fullStr.slice(0, half);
      const end = fullStr.slice(fullStr.length - (charsToRemain - half));
      return start + separator + end;
    }
  }
};
