import { useUIResourcePool } from '@/ducks/common/resources';

import { type NavigationStepSetting } from '../types';
import { StepSkeleton } from './StepSkeleton';

type LabelProps = {
  activeStep: number;
  isLoading: boolean;
} & Pick<NavigationStepSetting, 'label' | 'subLabel'>;

export const Label = ({ isLoading, label, subLabel }: LabelProps) => {
  const { desktopLabel, mobileLabel } = useUIResourcePool({
    desktopLabel: label.desktop.id,
    mobileLabel: label.mobile.id,
  });

  const { renderer: LabelRenderer } = label;
  const { isVisible, renderer: SubLabelRenderer } = subLabel;

  return (
    <>
      <span
        aria-hidden="true"
        className={`Progress__label desktop`}
        data-desktop={desktopLabel}
        suppressHydrationWarning
      >
        <LabelRenderer>{desktopLabel}</LabelRenderer>
      </span>
      <span aria-hidden="true" className={`Progress__label mobile`} data-mobile={mobileLabel} suppressHydrationWarning>
        <LabelRenderer>{mobileLabel}</LabelRenderer>
      </span>

      <span aria-hidden="true" className="Progress__subLabel" suppressHydrationWarning>
        {!isLoading ? isVisible && <SubLabelRenderer /> : <StepSkeleton />}
      </span>
    </>
  );
};
