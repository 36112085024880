import { useSearchParams } from 'next/navigation';
import { useMemo } from 'react';

import type { TCurrencyCode } from '@/infra/types/common';

import { Price } from '@/components/elements';
import { useUIResourcePool } from '@/ducks/common/resources';
import { pickAnyFromSearchParams } from '@/ducks/pages/navigation';
import { selectCalculateInvoiceIsLoading } from '@/ducks/pages/summary/selectors';
import { routes } from '@/ducks/routes';
import { selectIsSailingDataEmpty } from '@/ducks/voyageInfo/sailing/selectors';
import getSymbolFromCurrenciesData from '@/helpers/util/currency/currencySymbols';
import { truncateVoyageNameStep } from '@/helpers/util/truncate';
import { useIsMobile } from '@/hooks/useIsDevice';
import useWindowSize from '@/hooks/useWindowSize';
import { useAppSelector } from '@/store';

import { type NavigationStepSetting, type UseGetNavigationStepsProps } from './types';

const useGetNavigationSteps = ({
  activeStep,
  cabinCategory,
  price,
  voyageDates,
  voyageName,
}: UseGetNavigationStepsProps) => {
  const isMobile = useIsMobile();
  const { labelExclDesktop, labelExclMobile, labelInclDesktop, labelInclMobile } = useUIResourcePool(UI_RESOURCE_POOL);

  const taxes = useMemo(() => {
    const label = isMobile
      ? price?.taxInclusive
        ? labelInclMobile
        : labelExclMobile
      : price?.taxInclusive
        ? labelInclDesktop
        : labelExclDesktop;

    return label?.trim();
  }, [isMobile, price?.taxInclusive, labelInclMobile, labelExclMobile, labelInclDesktop, labelExclDesktop]);

  const params = useSearchParams();
  const charterId = params.get('charterId');

  const isSailingDataEmpty = useAppSelector(selectIsSailingDataEmpty);
  const isInvoiceEmpty = useAppSelector(selectCalculateInvoiceIsLoading);

  const navigationSettingProps: NavigationSettingProps = {
    activeStep,
    cabinCategory,
    charterId,
    isInvoiceEmpty,
    isSailingDataEmpty,
    price,
    taxes,
    voyageDates,
    voyageName,
  };

  return enumerateSteps(
    [getChooseVoyageStep, getChooseCabinStep, getSummaryStep, getPaymentStep].map((setting) =>
      setting(navigationSettingProps),
    ),
  );
};

type NavigationSettingProps = {
  charterId: null | string;
  isInvoiceEmpty: boolean;
  isSailingDataEmpty: boolean;
  taxes: string;
} & UseGetNavigationStepsProps;

type GetCVPStepSubLabelProps = {
  voyageDates: string;
  voyageName: string;
};

const UI_RESOURCE_POOL = {
  labelExclDesktop: 'MainNav.Summary.tax.excluding.desktop',
  labelExclMobile: 'MainNav.Summary.tax.excluding.mobile',
  labelInclDesktop: 'MainNav.Summary.tax.including.desktop',
  labelInclMobile: 'MainNav.Summary.tax.including.mobile',
} as const;

const enumerateSteps = (steps: NavigationStepSetting[]) => steps.map((step, idx) => ({ id: idx + 1, ...step }));

const DefaultLabelRenderer = ({ children }: { children: React.ReactNode }) => children;

const GetCVPStepSubLabel = (props: GetCVPStepSubLabelProps) => {
  const { voyageDates, voyageName } = props;
  const { width } = useWindowSize();

  return (
    <>
      {truncateVoyageNameStep(voyageName, width)}
      {' ∙ '}
      {voyageDates}
    </>
  );
};

const getChooseVoyageStep = (props: NavigationSettingProps): NavigationStepSetting => ({
  isLoading: props.activeStep >= 1 && props.isSailingDataEmpty,
  label: {
    desktop: {
      defaultMessage: 'Your voyage',
      id: 'MainNav.Summary.yourVoyage.desktop',
    },
    mobile: {
      defaultMessage: 'Voyage',
      id: 'MainNav.Summary.yourVoyage.mobile',
    },
    renderer: DefaultLabelRenderer,
    screenReader: {
      id: 'MainNav.Summary.yourVoyage.screenReader',
      values: { chosenVoyage: props.voyageName },
    },
  },
  subLabel: {
    isVisible: props.activeStep >= 1,
    renderer: () => <GetCVPStepSubLabel voyageDates={props.voyageDates} voyageName={props.voyageName} />,
  },
  uri: () =>
    `${routes.planner.preCheckOut.path}?${new URLSearchParams(pickAnyFromSearchParams({ withCharter: true, withChooseSailing: true, withFilters: true }))}`,
});

const getChooseCabinStep = (props: NavigationSettingProps): NavigationStepSetting => ({
  isLoading: props.activeStep === 3 && (props.isSailingDataEmpty || props.isInvoiceEmpty),
  label: {
    desktop: {
      defaultMessage: 'Choose cabin',
      id: 'MainNav.ChooseCabin.name.desktop',
    },
    mobile: {
      defaultMessage: 'Cabins',
      id: 'MainNav.ChooseCabin.name.mobile',
    },
    renderer: DefaultLabelRenderer,
  },
  subLabel: {
    isVisible: props.activeStep > 2,
    renderer: () => <>{props.cabinCategory}</>,
  },
  uri: () =>
    `${routes.planner.chooseCabin.path}?${new URLSearchParams(
      pickAnyFromSearchParams({ withCharter: true, withChooseSailing: true, withFilters: true }),
    )}`,
});

const getSummaryStep = (props: NavigationSettingProps): NavigationStepSetting => {
  const voyagePrice = props.price?.amount || 0;
  const currencyCode = getSymbolFromCurrenciesData(props.price?.currencyCode);
  const hasPrice = voyagePrice > 0;

  const priceRender = hasPrice ? (
    <Price amount={voyagePrice} currencyCode={props.price?.currencyCode as TCurrencyCode} />
  ) : null;

  return {
    isLoading: props.activeStep === 3 && (props.isSailingDataEmpty || props.isInvoiceEmpty),
    label: {
      desktop: {
        defaultMessage: 'Voyage Details',
        id: 'MainNav.Summary.voyageDetails.desktop',
      },
      mobile: {
        defaultMessage: 'Details',
        id: 'MainNav.Summary.voyageDetails.mobile',
      },
      renderer: DefaultLabelRenderer,
      screenReader: {
        id: hasPrice
          ? 'MainNav.Summary.voyageDetailsWithPrice.screenReader'
          : 'MainNav.Summary.voyageDetails.screenReader',
        values: {
          currencyCode,
          price: voyagePrice,
          taxes: props.taxes,
        },
      },
    },
    subLabel: {
      isVisible: true,
      renderer: () =>
        props.activeStep >= 3 && (
          <>
            {priceRender}
            {props.taxes && <> ({props.taxes})</>}
          </>
        ),
    },
    uri: () => routes.planner.summary.path,
  };
};

const getPaymentStep = (_: NavigationSettingProps): NavigationStepSetting => ({
  isLoading: false,
  label: {
    desktop: {
      defaultMessage: 'Payment',
      id: 'MainNav.Payment.name.desktop',
    },
    mobile: {
      defaultMessage: 'Payment',
      id: 'MainNav.Payment.name.mobile',
    },
    renderer: DefaultLabelRenderer,
  },
  subLabel: {
    isVisible: false,
    renderer: () => <></>,
  },
  uri: () => routes.planner.payment.path,
});

export default useGetNavigationSteps;
