import { useMemo, useRef } from 'react';

import isEqual from 'lodash/isEqual';

import { type TOptional } from '@/types/common';

const useStrictMemo = <R>(compute: () => R, dependencies: readonly unknown[]): R => {
  const resultRef = useRef<TOptional<R>>(undefined);

  return useMemo(() => {
    const next = compute();
    if (!isEqual(next, resultRef.current)) resultRef.current = next;
    return resultRef.current!;
  }, dependencies);
};

export default useStrictMemo;
