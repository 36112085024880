import Link from 'next/link';
import { useRouter, useSearchParams } from 'next/navigation';
import type { KeyboardEventHandler } from 'react';

import cn from 'classnames';
import isEmpty from 'lodash/isEmpty';
import trimEnd from 'lodash/trimEnd';

import { useUIResourceProps } from '@/ducks/common/resources/hooks';
import { ChooseCategorySearchParams } from '@/ducks/voyageInfo/category/searchParams';
import makeAbsUrlByRouteUrl from '@/helpers/url/makeAbsUrlByRouteUrl';
import { useAppSelector } from '@/store';

import type { NavigationStepSetting } from '../types';

const CHOOSE_VOYAGE_STEP = 1;
const CHOOSE_CABIN_STEP = 2;

type StepProps = {
  activeStep: number;
  children: React.ReactNode;
  id: number;
  maxStep: number;
} & Pick<NavigationStepSetting, 'label' | 'uri'>;

export const Step = ({ activeStep, children, id, label, maxStep, uri }: StepProps) => {
  const params = useSearchParams();
  const promotionalCabinType = useAppSelector((state) => state?.promotions?.data?.cabinType) ?? '';
  const promotionalVoyages: string[] = useAppSelector((state) => state?.promotions?.data?.voyageId) ?? [];
  const router = useRouter();

  const isComplete = id < activeStep;
  const isActive = id === activeStep;
  const classNames = cn('Progress__step', {
    '-isActive': isActive,
    '-isComplete': isComplete,
  });
  const style = maxStep ? { flexBasis: `${100 / maxStep}%` } : {};

  let Component;
  if (activeStep === CHOOSE_VOYAGE_STEP) {
    Component = 'span';
  } else {
    Component = isActive || isComplete ? Link : 'span';
  }

  const buildLink = () => {
    if (isActive || isComplete) {
      const link = makeAbsUrlByRouteUrl(trimEnd(uri(), '? '), params);
      if (link) {
        const { searchParams } = link;
        if ([CHOOSE_CABIN_STEP, CHOOSE_VOYAGE_STEP].includes(id)) {
          Object.values(ChooseCategorySearchParams).forEach((value: string) => searchParams.delete(value));
        }

        if (!isEmpty(promotionalVoyages) && id === CHOOSE_VOYAGE_STEP) {
          const selectedVoyage = params.get('voyageId');
          promotionalVoyages.forEach((voyage) => {
            if (voyage !== selectedVoyage) searchParams.append('voyageId', voyage);
          });
          const selectedCabinType = params.get(ChooseCategorySearchParams.chosenSubCategoryCode);
          if (promotionalCabinType !== selectedCabinType) {
            searchParams.set(ChooseCategorySearchParams.chosenSubCategoryCode, promotionalCabinType);
          }
        }
        return link.toString();
      }
    }
    return '';
  };

  const handleAction = () => {
    router.push(buildLink());
  };

  const handleOnKeyDown: KeyboardEventHandler<HTMLAnchorElement> = (event) => {
    if (event.code === 'Space' || event.code === 'Enter') {
      event.preventDefault();
      handleAction();
    }
  };

  const ariaLabel = useUIResourceProps(label.screenReader || label.desktop);

  return (
    <li aria-current={isActive ? 'step' : 'false'} className={classNames} style={style}>
      <span className="Progress__bottom" />
      <Component
        aria-disabled={!isActive && !isComplete ? 'true' : undefined}
        aria-label={`Step ${id} of ${maxStep}, ${ariaLabel}`}
        className="Progress__link"
        href={buildLink()}
        id={`Step-${id}`}
        onClick={handleAction}
        onKeyDown={handleOnKeyDown}
        role="button"
        tabIndex={isActive || isComplete ? 0 : -1}
      >
        {children}
        <span className="Progress__bar" />
        <span className="Progress__marker" />
      </Component>
      <div className="arc arc_start" />
      <div className="arc arc_end" />
    </li>
  );
};
