'use client';

import { usePathname } from 'next/navigation';

import useFlowNavigationPrice from '@/containers/VoyagePlanner/Summary/hooks/useFlowNavigationPrice';
import { routes } from '@/ducks/routes';

import { ProgressBar } from './ProgressBar';

const STEP_ROUTES = [
  routes.planner.preCheckOut.path,
  routes.planner.chooseCabin.path,
  routes.planner.summary.path,
  routes.planner.payment.path,
];

const FlowNavigation = () => {
  const pathname = usePathname();
  const price = useFlowNavigationPrice();
  const activeStep = STEP_ROUTES.findIndex((route) => pathname.startsWith(route)) + 1;
  // TODO: Simplify ProgressBar component
  return <ProgressBar activeStep={activeStep} price={price} />;
};

export default FlowNavigation;
