import { useMemo } from 'react';

import isEmpty from 'lodash/isEmpty';

import { selectSailingData } from '@/ducks/voyageInfo/sailing/selectors';
import { getDatesRange } from '@/helpers/util/dateUtil';
import { useAppSelector } from '@/store';

import type { TPrice } from '../types';

import useGetNavigationSteps from '../useGetNavigationSteps';
import { Label } from './Label';
import { Step } from './Step';

import './Progress.scss';

type ProgressProps = {
  activeStep: number;
  cabinCategory: string;
  price?: TPrice;
};

export const Progress = ({ activeStep, cabinCategory, price }: ProgressProps) => {
  const { debarkDate, embarkDate, name: voyageName } = useAppSelector(selectSailingData) || {};
  const voyageDates = useMemo(() => getDatesRange({ from: embarkDate, to: debarkDate }), [embarkDate, debarkDate]);

  const steps = useGetNavigationSteps({
    activeStep,
    cabinCategory,
    price,
    voyageDates,
    voyageName,
  });

  return (
    <ol className="Progress -container -stepMask" id="ProgressBar">
      {steps.map((step) => (
        <Step
          activeStep={activeStep}
          id={step.id}
          key={step.id}
          label={step.label}
          maxStep={isEmpty(steps) ? 0 : steps.length}
          uri={step.uri}
        >
          <Label activeStep={activeStep} isLoading={step.isLoading} label={step.label} subLabel={step.subLabel} />
        </Step>
      ))}
    </ol>
  );
};
