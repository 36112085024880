import Image from 'next/image';
import Link from 'next/link';
import { useSearchParams } from 'next/navigation';
import { useMemo } from 'react';

import { useSelector } from 'react-redux';

import Arrow from '@publicImages/svg/arrow-right.svg?icon';

import { clearSailingData } from '@/actions';
import Button from '@/components/elements/Button';
import * as Dialog from '@/components/elements/Dialog';
import UIResource from '@/components/UIResource';
import { type TUIResourceImage, useUIResource } from '@/ducks/common/resources';
import { pickAnyFromSearchParams } from '@/ducks/pages/navigation';
import { exitCelebrationsReferredBookerFlowAction } from '@/ducks/programs/celebrations/actions';
import { selectCelebrationsIsFlowActive } from '@/ducks/programs/celebrations/selectors';
import { routes } from '@/ducks/routes';
import { resetTravelParty } from '@/ducks/travelParty/slice';
import { selectSailingData } from '@/ducks/voyageInfo/sailing/selectors';
import { useIsPromoActive } from '@/features/promo/lib/useIsPromoActive';
import { MerchandisingBanner } from '@/features/promo/MerchandisingBanner';
import { LEGAL_NAME, useLegalLinks } from '@/hooks/useLegalLink';
import { useAppDispatch } from '@/store';
import tagmanager from '@/tagmanager';

import styles from './ExitModal.module.scss';

type Props = {
  isCharter: boolean;
  onLinkClick?: (event: React.MouseEvent<HTMLAnchorElement>) => void;
};

export const ExitModal = ({ isCharter, onLinkClick }: Props) => {
  const contactSailorServicesUrl = useLegalLinks(LEGAL_NAME.CONTACT_US) ?? '';
  const dispatch = useAppDispatch();
  const sailingData = useSelector(selectSailingData);
  const isCelebrationFlowActive = useSelector(selectCelebrationsIsFlowActive);
  const isPromotionEnabled = useIsPromoActive('exitInterstitial', sailingData);
  const searchParams = useSearchParams();

  const avatar = useUIResource<TUIResourceImage>('ChooseVoyage.Exit.icon');

  const homeLink = useMemo(() => {
    const pathname = routes.planner.chooseVoyage.path;

    if (isCelebrationFlowActive) {
      return {
        pathname,
      };
    }

    return {
      pathname,
      query: pickAnyFromSearchParams({ outerSearchParams: searchParams, withCharter: true, withFilters: true }),
    };
  }, [isCelebrationFlowActive, searchParams]);

  const onPromoCtaClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    const label = event.currentTarget.innerText;
    tagmanager.tracker.blackFriday.onExitModalClick(label);
  };

  const handleItinerariesClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    onLinkClick?.(event);
    dispatch(resetTravelParty());
    dispatch(clearSailingData());
    dispatch(exitCelebrationsReferredBookerFlowAction());
  };

  const handleHomePageClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    onLinkClick?.(event);
    dispatch(resetTravelParty());
    dispatch(clearSailingData());
    dispatch(exitCelebrationsReferredBookerFlowAction());
  };

  return (
    <Dialog.Content
      className={styles.dialogContent}
      closeSlot={<Dialog.CloseButton aria-label="Close exit booking flow dialog" className={styles.closeButton} />}
      containerClassName={styles.dialogContainer}
      role="document"
    >
      <div className={styles.content}>
        <div className={styles.heading}>
          {avatar && <Image alt={avatar.alt} height={58} src={avatar.src} width={40} />}
          <Dialog.Title asChild>
            <span className={styles.title}>
              {isCharter ? (
                <UIResource id="charter.exitInterstitial.description" />
              ) : (
                <UIResource id={isPromotionEnabled ? 'Promotion.exit.heading' : 'ChooseVoyage.Exit.heading'} />
              )}
            </span>
          </Dialog.Title>
        </div>

        {isPromotionEnabled && !isCharter && (
          <div className={styles.promoCtaBlock}>
            <MerchandisingBanner type="countdownClock" />

            <Dialog.Close asChild>
              <Button onClick={onPromoCtaClick} tabIndex={0} variant="primary">
                <UIResource id="Promotion.exit.CTA" />
              </Button>
            </Dialog.Close>
          </div>
        )}

        <Dialog.Description asChild>
          <div className={styles.linksContainer}>
            {isCharter ? (
              <>
                <Dialog.Close asChild>
                  <Button className={styles.inlineCTA} onClick={onPromoCtaClick} tabIndex={0} variant="text">
                    <UIResource id="charter.exitInterstitial.BacktoBooking.CTA" />
                    <Arrow />
                  </Button>
                </Dialog.Close>
                <a className={styles.link} href="/" onClick={handleHomePageClick} tabIndex={0}>
                  <UIResource id="charter.exitInterstital.BacktoHome.CTA" />
                  <Arrow />
                </a>
              </>
            ) : (
              <>
                <Link
                  className={styles.link}
                  href={homeLink}
                  onClick={handleItinerariesClick}
                  prefetch={false}
                  tabIndex={0}
                >
                  <UIResource id="ChooseVoyage.Exit.SearchItinerariesCTA" />
                  <Arrow />
                </Link>
                <a className={styles.link} href="/" onClick={handleHomePageClick} tabIndex={0}>
                  <UIResource id="ChooseVoyage.Exit.ReturnHomeCTA" />
                  <Arrow />
                </a>
              </>
            )}

            <a
              className={styles.link}
              href={contactSailorServicesUrl}
              onClick={onLinkClick}
              tabIndex={0}
              target="_blank"
            >
              <UIResource id="ChooseVoyage.Exit.ContactSailorServicesCTA" />
              <Arrow />
            </a>
          </div>
        </Dialog.Description>
      </div>
    </Dialog.Content>
  );
};
