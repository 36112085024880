import Image from 'next/image';
import { useSearchParams } from 'next/navigation';
import { useCallback, useMemo, useRef, useState } from 'react';

import cn from 'classnames';

import logo from '@publicImages/logo.png';
import Close from '@publicImages/svg/close.svg?icon';

import * as Dialog from '@/components/elements/Dialog';
import IconButton from '@/components/elements/IconButton';
import { openCelebrationModal } from '@/components/Promotion/CelebrationBanner/helpers';
import useCustomArrowKeys from '@/ducks/a11y/hooks/useCustomArrowKeys';
import { useUIResource } from '@/ducks/common/resources';
import { getFilterAgent } from '@/ducks/fm/getters';
import { selectCabinNamesByCodesMap } from '@/ducks/pages/chooseCabin/selectors';
import { selectCabinInvoices } from '@/ducks/pages/summary/selectors';
import { selectCelebrationsIsFlowActive } from '@/ducks/programs/celebrations/selectors';
import { routes } from '@/ducks/routes';
import { env } from '@/environment';
import { useIsLoggedIn } from '@/helpers/auth/useIsLoggedIn';
import { StickyBarsManager, useStickyBarOnScroll } from '@/helpers/managers/stickyBars';
import { getSessionStorageValue } from '@/helpers/util/storage';
import useOnKeyDown from '@/hooks/useOnKeyDown';
import { useAppSelector } from '@/store';
import tagmanager from '@/tagmanager';

import { ExitModal } from './ExitModal';
import { Progress } from './Progress';
import { type TPrice } from './types';

import './ProgressBar.scss';

// TODO: Simplify this component

export const progressBarStickyManager = new StickyBarsManager()
  .subscribe({
    barClassName: 'ProgressBar',
    id: 'progressBar',
  })
  .subscribe({
    barClassName: 'ProgressBar__top',
    id: 'progressBarLogo',
  });

type ProgressBarProps = {
  activeStep: number;
  isFMBanner?: boolean;
  price?: TPrice;
};

export const ProgressBar = ({ activeStep, price }: ProgressBarProps) => {
  const { cabinTypeCode, name: cabinName } =
    useAppSelector(selectCabinInvoices)?.[0] ?? getSessionStorageValue('invoiceData')?.cabinInvoices?.[0] ?? {};
  const cabinNames = useAppSelector(selectCabinNamesByCodesMap);
  const isCelebrationFlow = useAppSelector(selectCelebrationsIsFlowActive);

  const cabinCategory = cabinName || cabinNames?.[cabinTypeCode!] || getSessionStorageValue('suiteName') || '';

  const isFMBanner = useMemo(() => getFilterAgent()?.data?.isValid || false, []);

  const ref = useRef(null);
  useCustomArrowKeys({ element: ref.current });

  useStickyBarOnScroll({
    manager: progressBarStickyManager,
  });

  const [isExitDialogOpen, setIsExitDialogOpen] = useState(false);

  const handleLogoClick = useCallback(
    (event: React.UIEvent) => {
      event.preventDefault();
      if (isCelebrationFlow) openCelebrationModal();
      else setIsExitDialogOpen(true);
    },
    [isCelebrationFlow],
  );

  const onLogoKeyDown = useOnKeyDown(handleLogoClick);

  const onDialogOpenChange = (isOpen: boolean) => {
    setIsExitDialogOpen(isOpen);
    tagmanager.tracker.common.trackPopupState({
      isOpen,
      module: tagmanager.trackerConstants.FLYOUTS.NAVIGATION_HELPER_POPUP,
    });
  };

  const onDialogLinkClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    const label = event.currentTarget.innerText;
    tagmanager.tracker.common.trackButtonClick({
      labels: [label],
      module: tagmanager.trackerConstants.FLYOUTS.NAVIGATION_HELPER_POPUP,
    });
  };

  const logoAltLabel = useUIResource('MainNav.VVLogo.alt');

  const params = useSearchParams();
  const isCharter = !!params.get('charterId');

  const isLoggedIn = useIsLoggedIn();

  return (
    <>
      <nav className={cn('ProgressBar', { isFMBanner })} ref={ref}>
        <div className="ProgressBar__outer">
          <div className="ProgressBar__inner">
            <div className="ProgressBar__top">
              <div className="ProgressBar__close">
                <Dialog.Root onOpenChange={onDialogOpenChange} open={isExitDialogOpen}>
                  <Dialog.Trigger asChild>
                    <IconButton
                      aria-label="Exit booking flow"
                      className="ProgressBar__close__btn"
                      onClick={handleLogoClick}
                      tabIndex={0}
                    >
                      <Close />
                    </IconButton>
                  </Dialog.Trigger>
                  <ExitModal isCharter={isCharter} onLinkClick={onDialogLinkClick} />
                </Dialog.Root>
              </div>

              <div className="ProgressBar__logoContainer">
                <Image
                  alt=""
                  aria-label={logoAltLabel}
                  className="ProgressBar__logo"
                  height={45}
                  onClick={handleLogoClick}
                  onKeyDown={onLogoKeyDown}
                  role="button"
                  src={logo}
                  tabIndex={0}
                  width={45}
                />
              </div>

              {(!isCharter || (isCharter && isLoggedIn)) && (
                <account-dropdown
                  afterLogoutUrl={`${env.CONTEXT}${routes.planner.chooseVoyage.path}`}
                  authLogoutUrl={`${env.AUTH_BASE_URL}/logout`}
                  isCharter={isCharter}
                />
              )}
            </div>

            <Progress activeStep={activeStep} cabinCategory={cabinCategory} price={price} />
          </div>
        </div>
      </nav>
      <div className="Underlay" />
    </>
  );
};
