import { useEffect } from 'react';

export const useStickyBarOnScroll = ({ manager }) => {
  useEffect(() => {
    let prevOffset = window.scrollY;

    const onScroll = () => {
      const forceExpandOnTop = window.scrollY < manager.getTopOffsetToExpandForcely();
      const isScrollToTop = prevOffset > window.scrollY;

      manager.notify(isScrollToTop || forceExpandOnTop);

      prevOffset = window.scrollY;
    };

    window.addEventListener('scroll', onScroll);

    return () => {
      window.removeEventListener('scroll', onScroll);
    };
  }, [manager]);
};
