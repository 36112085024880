import { backToMainPage } from '@/ducks/voyageInfo/category/navigation';

export const HANDLE_OPEN_CELEBRATION_MODAL_EVENT = 'CelebrationBanner.handleOpenModal';
export const openCelebrationModal = () => {
  const event = new Event(HANDLE_OPEN_CELEBRATION_MODAL_EVENT);
  window.dispatchEvent(event);
};

export const getCelebrationOnBackHandler = (isCelebrationFlow: boolean) =>
  isCelebrationFlow ? openCelebrationModal : backToMainPage;
