const DEFAULT_TOP_OFFSET_TOP_EXPAND_FORCELY = 125;

export class StickyBarsManager {
  #bars = [];

  #state = true;

  // dont collapse if below this value
  #topOffsetToExpandForcely = DEFAULT_TOP_OFFSET_TOP_EXPAND_FORCELY;

  getState() {
    return this.#state;
  }

  getSubscribers() {
    return this.#bars;
  }

  getTopOffsetToExpandForcely() {
    return this.#topOffsetToExpandForcely;
  }

  notify(state) {
    this.#bars.forEach(({ barShrinkAnimationAttribute = 'data-hide-bar-on-scroll-down', onHide, onShow }) => {
      if (state) {
        onShow?.();
        if (barShrinkAnimationAttribute) {
          document.body.removeAttribute(barShrinkAnimationAttribute);
        }
      } else {
        onHide?.();
        if (barShrinkAnimationAttribute) {
          document.body.setAttribute(barShrinkAnimationAttribute, true);
        }
      }
      this.setState(state);
    });
  }

  resetTopOffsetToExpandForcely() {
    this.#topOffsetToExpandForcely = DEFAULT_TOP_OFFSET_TOP_EXPAND_FORCELY;

    return this;
  }

  setState(state) {
    this.#state = state;
    return this;
  }

  setTopOffsetToExpandForcely(offset) {
    this.#topOffsetToExpandForcely = offset;

    return this;
  }

  subscribe(barOptions) {
    this.#bars.push(barOptions);
    return this;
  }

  unsubscribe(idToRemove) {
    this.#bars = this.#bars.filter(({ id }) => id !== idToRemove);
    return this;
  }
}
