import { forwardRef } from 'react';

import * as Primitive from '@radix-ui/react-dialog';
import cn from 'classnames';

import CrossIcon from '@publicImages/svg/close-without-bg.svg?static';

import styles from './Dialog.module.scss';

export { Close, Description, type DialogProps, Root, Title } from '@radix-ui/react-dialog';

export const Trigger = forwardRef<
  React.ElementRef<typeof Primitive.Trigger>,
  React.ComponentPropsWithoutRef<typeof Primitive.Trigger>
>((props, ref) => <Primitive.Trigger aria-expanded={undefined} ref={ref} {...props} />);

Trigger.displayName = 'DialogTrigger';

export const Isolator = ({ children }: { children: React.ReactNode }) => (
  <div
    data-prevent-nprogress={true}
    onClick={(e) => {
      e.preventDefault();
      e.stopPropagation();
    }}
  >
    {children}
  </div>
);

export const focusVisibleTrigger = (e: Event) => {
  e.preventDefault();
  const elem = e.currentTarget as Element | null;
  const triggers = document.querySelectorAll<HTMLElement>(`[aria-controls="${elem?.id}"`);
  for (const node of triggers) {
    if (node.checkVisibility() && node.tabIndex !== -1) {
      node.focus();
      break;
    }
  }
};

export const CloseButton = ({ className, ...rest }: Primitive.DialogCloseProps) => (
  <Primitive.Close className={cn(styles.close, className)} tabIndex={0} {...rest}>
    <CrossIcon />
  </Primitive.Close>
);

export const Content = forwardRef<
  React.ElementRef<typeof Primitive.Content>,
  React.ComponentPropsWithoutRef<typeof Primitive.Content> & {
    closeSlot?: React.ReactNode;
    containerClassName?: string;
    descriptionId?: string; // ID for aria-describedby
    multipleTriggers?: boolean;
    overlayClassName?: string;
    titleId?: string; // ID for aria-labelledby
  }
>(
  (
    {
      children,
      className,
      closeSlot,
      containerClassName,
      descriptionId,
      multipleTriggers,
      overlayClassName,
      title,
      titleId,
      ...props
    },
    ref,
  ) => {
    return (
      <Primitive.Portal>
        <Primitive.Overlay className={cn(styles.overlay, overlayClassName)} />
        <Primitive.Content
          className={cn(styles.fixedContainer, containerClassName)}
          onCloseAutoFocus={multipleTriggers ? focusVisibleTrigger : undefined}
          {...props}
          aria-describedby={descriptionId || undefined} // Set only if descriptionId exists; otherwise, remove the default value
          aria-labelledby={titleId || undefined} // Set only if titleId exists; otherwise, remove the default value
          aria-modal
          ref={ref}
        >
          {closeSlot !== undefined ? closeSlot : <CloseButton aria-label={`Close ${title ?? ''} dialog`} />}
          <div aria-label="Dialog content" className={cn(styles.content, className)}>
            {children}
          </div>
        </Primitive.Content>
      </Primitive.Portal>
    );
  },
);
Content.displayName = 'DialogContent';
